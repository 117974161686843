/* ****************
 * GENERATED CODE *
 **************** */
import { BasicEvent } from "@h4x/common";
import { HewSyncList } from "../../system/HewSyncList";
import { HewSync } from "../../system/HewSync";
import { BaseType } from "../../system/BaseType";
import type { BaseOrganization } from "./Organization";

// Abstract
export class BaseRole extends BaseType {
	@HewSync.Field({ createOnly: true, generated: "util.autoId()", type: "ID" })
	public readonly role: HewSync.ID<BaseRole>;

	@HewSync.Field({ prefix: "ORGANIZATION", reference: "Organization", type: "ID" })
	public readonly organization: HewSync.ID<BaseOrganization>;

	@HewSync.Field({ type: "String", required: true })
	public readonly name: string;

	@HewSync.Field({ type: "String", default: '""' })
	public readonly description: string;

	@HewSync.Field({ type: "[Permission]", input: "[PermissionInput]", default: "[]", rustType: "Value" })
	public readonly permissions: HewSync.Permission[];
}
