/* ****************
 * GENERATED CODE *
 **************** */
import { BasicEvent } from "@h4x/common";
import { HewSyncList } from "../../system/HewSyncList";
import { HewSync } from "../../system/HewSync";
import { BaseRole } from "../base/Role";
import { AdminOrganization } from "./Organization";

@HewSync.Type({
	scope: "admin",
	name: "Role",
	table: "admin",
	parent: AdminOrganization,
	prefix: "ROLE"
})
export class AdminRole extends BaseRole {
	public static readonly type = "AdminRole";
	public readonly type = "AdminRole";

	@HewSync.Field({ createOnly: true, generated: "util.autoId()", type: "ID" })
	public override readonly role: HewSync.ID<AdminRole>;

	@HewSync.Field({ prefix: "ORGANIZATION", reference: "Organization", type: "ID" })
	public override readonly organization: HewSync.ID<AdminOrganization>;

	private static cache = new Map<string, AdminRole>();

	public static get(organization: HewSync.ID<AdminOrganization>, role: HewSync.ID<AdminRole>) {
		this.initSubscriptions();

		let key = this.getKey(organization, role);
		let item = this.cache.get(key)!;

		if (item === undefined) {
			item = new AdminRole({
				organization: organization.value,
				role: role.value,
				createdAt: "",
				updatedAt: "",
				name: "",
				description: "",
				permissions: []
			});
			this.cache.set(key, item);

			void (async () => {
				await HewSync.authPromise;
				item.fetch([
					new HewSync.QueryVariable("organization", "ID", organization.value),
					new HewSync.QueryVariable("role", "ID", role.value)
				]);
			})();
		}

		return item;
	}

	protected reset() {
		this.createdAt = "";
		this.updatedAt = "";
		this.name = "";
		this.description = "";
		this.permissions = [];
	}

	/**
	 * Raw get function, bypasses cache
	 *
	 * Do not use this function on the frontend, use get instead
	 */
	public static rawGet(organization: HewSync.ID<AdminOrganization>, role: HewSync.ID<AdminRole>) {
		return HewSync.queryGet<typeof AdminRole, AdminRole>(
			AdminRole,
			[
				new HewSync.QueryVariable("organization", "ID", organization.value),
				new HewSync.QueryVariable("role", "ID", role.value)
			],
			false
		);
	}

	public static list(
		filter?: Partial<AdminRole> | ((data: AdminRole) => boolean)
	): HewSyncList<typeof AdminRole, AdminRole> {
		if (typeof filter === "object") {
			let list = new HewSyncList<typeof AdminRole, AdminRole>(this, (data) => {
				let result = true;
				for (let key in filter) {
					if (Array.isArray(filter[key])) {
						if (filter[key].length !== data[key].length || !filter[key].every((x, i) => x === data[key][i])) {
							result = false;
							break;
						}
					} else if (filter[key] instanceof HewSync.ID) {
						if (data[key].value !== filter[key].value) {
							result = false;
							break;
						}
					} else if (data[key] !== filter[key]) {
						result = false;
						break;
					}
				}
				return result;
			});

			return list;
		} else {
			let list = new HewSyncList<typeof AdminRole, AdminRole>(this, filter);

			return list;
		}
	}

	public static listBy(
		params?: Partial<Pick<AdminRole, "organization" | "role">>
	): HewSyncList<typeof AdminRole, AdminRole> {
		let list = new HewSyncList<typeof AdminRole, AdminRole>(this, undefined, params);

		return list;
	}

	public static create(
		organization: HewSync.ID<AdminOrganization>,
		data: { name: string; description?: string; permissions?: HewSync.Permission[] }
	) {
		this.initSubscriptions();
		let inputs = [new HewSync.QueryVariable("organization", "ID", organization.value)];
		if (data.name !== undefined) {
			inputs.push(new HewSync.QueryVariable("name", "String", data.name));
		}
		if (data.description !== undefined) {
			inputs.push(new HewSync.QueryVariable("description", "String", data.description));
		}
		if (data.permissions !== undefined) {
			inputs.push(new HewSync.QueryVariable("permissions", "[PermissionInput]", data.permissions));
		}
		return HewSync.mutationCreate<typeof AdminRole, AdminRole>(AdminRole, inputs);
	}

	public static update(
		organization: HewSync.ID<AdminOrganization>,
		role: HewSync.ID<AdminRole>,
		data: { name?: string; description?: string; permissions?: HewSync.Permission[] },
		target?: AdminRole
	) {
		let inputs = [
			new HewSync.QueryVariable("organization", "ID", organization.value),
			new HewSync.QueryVariable("role", "ID", role.value)
		];
		if (data.name !== undefined) {
			inputs.push(new HewSync.QueryVariable("name", "String", data.name));
		}
		if (data.description !== undefined) {
			inputs.push(new HewSync.QueryVariable("description", "String", data.description));
		}
		if (data.permissions !== undefined) {
			inputs.push(new HewSync.QueryVariable("permissions", "[PermissionInput]", data.permissions));
		}
		return HewSync.mutationUpdate<typeof AdminRole, AdminRole>(AdminRole, inputs, target);
	}

	public update(data: { name?: string; description?: string; permissions?: HewSync.Permission[] }) {
		return AdminRole.update(this.organization, this.role, data, this);
	}

	public static remove(organization: HewSync.ID<AdminOrganization>, role: HewSync.ID<AdminRole>, target?: AdminRole) {
		return HewSync.mutationRemove<typeof AdminRole, AdminRole>(
			AdminRole,
			[
				new HewSync.QueryVariable("organization", "ID", organization.value),
				new HewSync.QueryVariable("role", "ID", role.value)
			],
			target
		);
	}

	public remove() {
		return AdminRole.remove(this.organization, this.role, this);
	}

	public static batchRemove(items: { organization: string; role: string }[]) {
		let inputs: HewSync.QueryVariable[] = [new HewSync.QueryVariable("items", "[Admin_RoleKey]", items)];
		return HewSync.mutationBatchRemove<typeof AdminRole, AdminRole>(AdminRole, inputs);
	}

	/* eslint-disable */
	protected constructor(data: any) {
		super(Symbol.for("HewSyncType::Internal::Create") as unknown as void);

		(this as any).createdAt = new HewSync.Timestamp(data.createdAt);
		(this as any).updatedAt = new HewSync.Timestamp(data.updatedAt);
		(this as any).role = new HewSync.ID<any>(data.role);
		(this as any).organization = new HewSync.ID<any>(data.organization);
		(this as any).name = data.name;
		(this as any).description = data.description;
		(this as any).permissions = data.permissions;
	}

	public get key() {
		return this.organization.value + "/" + this.role.value;
	}

	protected apply(data: any) {
		(this as any).createdAt = new HewSync.Timestamp(data.createdAt);
		(this as any).updatedAt = new HewSync.Timestamp(data.updatedAt);
		(this as any).name = data.name;
		(this as any).description = data.description;
		(this as any).permissions = data.permissions;

		if (data.removed) {
			(this.removed as any) = true;
			this.onRemove.execute(this);
			AdminRole.cache.delete(this.key);
		} else {
			this.onUpdate.execute(this);
		}
	}
	/* eslint-enable */

	protected static from(data: any): AdminRole {
		return new AdminRole(data);
	}

	public static getKey(organization: HewSync.ID<AdminOrganization>, role: HewSync.ID<AdminRole>) {
		return organization.value + "/" + role.value;
	}

	public static getKeyFromData(data: { organization: string; role: string }) {
		return `${data.organization + "/" + data.role}`;
	}

	private static onSubscriptionEvent = new BasicEvent<(data: any) => void>();

	private static subscriptionsInitialized = false;
	static initSubscriptions() {
		if (this.subscriptionsInitialized === false) {
			void HewSync.initSubscriptions(this, this.onSubscriptionEvent);
			this.subscriptionsInitialized = true;
		}
	}
}
