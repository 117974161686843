/* ****************
 * GENERATED CODE *
 **************** */
import { BasicEvent } from "@h4x/common";
import { HewSyncList } from "../../system/HewSyncList";
import { HewSync } from "../../system/HewSync";
import { BaseType } from "../../system/BaseType";
import { UserOrganization } from "../user/Organization";

@HewSync.Type({
	scope: "project",
	name: "Instance",
	table: "user",
	parent: UserOrganization,
	prefix: "INSTANCE"
})
export class ProjectInstance extends BaseType {
	public static readonly type = "ProjectInstance";
	public readonly type = "ProjectInstance";

	@HewSync.Field({ createOnly: true, generated: "util.autoId()", type: "ID" })
	public readonly instance: HewSync.ID<ProjectInstance>;

	@HewSync.Field({ prefix: "ORGANIZATION", reference: "Organization", type: "ID" })
	public readonly organization: HewSync.ID<UserOrganization>;

	@HewSync.Field({ type: "String", required: true })
	public readonly name: string;

	@HewSync.Field({ type: "String", required: true })
	public readonly template: string;

	@HewSync.Field({ type: "String", required: true })
	public readonly version: string;

	@HewSync.Field({ type: "String" })
	public readonly coreVersion: string;

	@HewSync.Field({ type: "String", default: '""' })
	public readonly color: string;

	@HewSync.Field({ default: "{}", type: "JSON" })
	public readonly settings: any;

	private static cache = new Map<string, ProjectInstance>();

	public static get(organization: HewSync.ID<UserOrganization>, instance: HewSync.ID<ProjectInstance>) {
		this.initSubscriptions();

		let key = this.getKey(organization, instance);
		let item = this.cache.get(key)!;

		if (item === undefined) {
			item = new ProjectInstance({
				organization: organization.value,
				instance: instance.value,
				createdAt: "",
				updatedAt: "",
				name: "",
				template: "",
				version: "",
				coreVersion: "",
				color: "",
				settings: "{}"
			});
			this.cache.set(key, item);

			void (async () => {
				await HewSync.authPromise;
				item.fetch([
					new HewSync.QueryVariable("organization", "ID", organization.value),
					new HewSync.QueryVariable("instance", "ID", instance.value)
				]);
			})();
		}

		return item;
	}

	protected reset() {
		this.createdAt = "";
		this.updatedAt = "";
		this.name = "";
		this.template = "";
		this.version = "";
		this.coreVersion = "";
		this.color = "";
		this.settings = "{}";
	}

	/**
	 * Raw get function, bypasses cache
	 *
	 * Do not use this function on the frontend, use get instead
	 */
	public static rawGet(organization: HewSync.ID<UserOrganization>, instance: HewSync.ID<ProjectInstance>) {
		return HewSync.queryGet<typeof ProjectInstance, ProjectInstance>(
			ProjectInstance,
			[
				new HewSync.QueryVariable("organization", "ID", organization.value),
				new HewSync.QueryVariable("instance", "ID", instance.value)
			],
			false
		);
	}

	public static list(
		filter?: Partial<ProjectInstance> | ((data: ProjectInstance) => boolean)
	): HewSyncList<typeof ProjectInstance, ProjectInstance> {
		if (typeof filter === "object") {
			let list = new HewSyncList<typeof ProjectInstance, ProjectInstance>(this, (data) => {
				let result = true;
				for (let key in filter) {
					if (Array.isArray(filter[key])) {
						if (filter[key].length !== data[key].length || !filter[key].every((x, i) => x === data[key][i])) {
							result = false;
							break;
						}
					} else if (filter[key] instanceof HewSync.ID) {
						if (data[key].value !== filter[key].value) {
							result = false;
							break;
						}
					} else if (data[key] !== filter[key]) {
						result = false;
						break;
					}
				}
				return result;
			});

			return list;
		} else {
			let list = new HewSyncList<typeof ProjectInstance, ProjectInstance>(this, filter);

			return list;
		}
	}

	public static listBy(
		params?: Partial<Pick<ProjectInstance, "organization" | "instance">>
	): HewSyncList<typeof ProjectInstance, ProjectInstance> {
		let list = new HewSyncList<typeof ProjectInstance, ProjectInstance>(this, undefined, params);

		return list;
	}

	public static create(
		organization: HewSync.ID<UserOrganization>,
		data: { name: string; template: string; version: string; coreVersion?: string; color?: string; settings?: any }
	) {
		this.initSubscriptions();
		let inputs = [new HewSync.QueryVariable("organization", "ID", organization.value)];
		if (data.name !== undefined) {
			inputs.push(new HewSync.QueryVariable("name", "String", data.name));
		}
		if (data.template !== undefined) {
			inputs.push(new HewSync.QueryVariable("template", "String", data.template));
		}
		if (data.version !== undefined) {
			inputs.push(new HewSync.QueryVariable("version", "String", data.version));
		}
		if (data.coreVersion !== undefined) {
			inputs.push(new HewSync.QueryVariable("coreVersion", "String", data.coreVersion));
		}
		if (data.color !== undefined) {
			inputs.push(new HewSync.QueryVariable("color", "String", data.color));
		}
		if (data.settings !== undefined) {
			inputs.push(new HewSync.QueryVariable("settings", "AWSJSON", JSON.stringify(data.settings)));
		}
		return HewSync.mutationCreate<typeof ProjectInstance, ProjectInstance>(ProjectInstance, inputs);
	}

	public static update(
		organization: HewSync.ID<UserOrganization>,
		instance: HewSync.ID<ProjectInstance>,
		data: { name?: string; template?: string; version?: string; coreVersion?: string; color?: string; settings?: any },
		target?: ProjectInstance
	) {
		let inputs = [
			new HewSync.QueryVariable("organization", "ID", organization.value),
			new HewSync.QueryVariable("instance", "ID", instance.value)
		];
		if (data.name !== undefined) {
			inputs.push(new HewSync.QueryVariable("name", "String", data.name));
		}
		if (data.template !== undefined) {
			inputs.push(new HewSync.QueryVariable("template", "String", data.template));
		}
		if (data.version !== undefined) {
			inputs.push(new HewSync.QueryVariable("version", "String", data.version));
		}
		if (data.coreVersion !== undefined) {
			inputs.push(new HewSync.QueryVariable("coreVersion", "String", data.coreVersion));
		}
		if (data.color !== undefined) {
			inputs.push(new HewSync.QueryVariable("color", "String", data.color));
		}
		if (data.settings !== undefined) {
			inputs.push(new HewSync.QueryVariable("settings", "AWSJSON", JSON.stringify(data.settings)));
		}
		return HewSync.mutationUpdate<typeof ProjectInstance, ProjectInstance>(ProjectInstance, inputs, target);
	}

	public update(data: {
		name?: string;
		template?: string;
		version?: string;
		coreVersion?: string;
		color?: string;
		settings?: any;
	}) {
		return ProjectInstance.update(this.organization, this.instance, data, this);
	}

	public static remove(
		organization: HewSync.ID<UserOrganization>,
		instance: HewSync.ID<ProjectInstance>,
		target?: ProjectInstance
	) {
		return HewSync.mutationRemove<typeof ProjectInstance, ProjectInstance>(
			ProjectInstance,
			[
				new HewSync.QueryVariable("organization", "ID", organization.value),
				new HewSync.QueryVariable("instance", "ID", instance.value)
			],
			target
		);
	}

	public remove() {
		return ProjectInstance.remove(this.organization, this.instance, this);
	}

	public static batchRemove(items: { organization: string; instance: string }[]) {
		let inputs: HewSync.QueryVariable[] = [new HewSync.QueryVariable("items", "[Project_InstanceKey]", items)];
		return HewSync.mutationBatchRemove<typeof ProjectInstance, ProjectInstance>(ProjectInstance, inputs);
	}

	/* eslint-disable */
	protected constructor(data: any) {
		super(Symbol.for("HewSyncType::Internal::Create") as unknown as void);

		(this as any).createdAt = new HewSync.Timestamp(data.createdAt);
		(this as any).updatedAt = new HewSync.Timestamp(data.updatedAt);
		(this as any).instance = new HewSync.ID<any>(data.instance);
		(this as any).organization = new HewSync.ID<any>(data.organization);
		(this as any).name = data.name;
		(this as any).template = data.template;
		(this as any).version = data.version;
		(this as any).coreVersion = data.coreVersion;
		(this as any).color = data.color;
		(this as any).settings = JSON.parse(data.settings);
	}

	public get key() {
		return this.organization.value + "/" + this.instance.value;
	}

	protected apply(data: any) {
		(this as any).createdAt = new HewSync.Timestamp(data.createdAt);
		(this as any).updatedAt = new HewSync.Timestamp(data.updatedAt);
		(this as any).name = data.name;
		(this as any).template = data.template;
		(this as any).version = data.version;
		(this as any).coreVersion = data.coreVersion;
		(this as any).color = data.color;
		(this as any).settings = JSON.parse(data.settings);

		if (data.removed) {
			(this.removed as any) = true;
			this.onRemove.execute(this);
			ProjectInstance.cache.delete(this.key);
		} else {
			this.onUpdate.execute(this);
		}
	}
	/* eslint-enable */

	protected static from(data: any): ProjectInstance {
		return new ProjectInstance(data);
	}

	public static getKey(organization: HewSync.ID<UserOrganization>, instance: HewSync.ID<ProjectInstance>) {
		return organization.value + "/" + instance.value;
	}

	public static getKeyFromData(data: { organization: string; instance: string }) {
		return `${data.organization + "/" + data.instance}`;
	}

	private static onSubscriptionEvent = new BasicEvent<(data: any) => void>();

	private static subscriptionsInitialized = false;
	static initSubscriptions() {
		if (this.subscriptionsInitialized === false) {
			void HewSync.initSubscriptions(this, this.onSubscriptionEvent);
			this.subscriptionsInitialized = true;
		}
	}
}
