/* ****************
 * GENERATED CODE *
 **************** */
import { BasicEvent } from "@h4x/common";
import { HewSyncList } from "../../system/HewSyncList";
import { HewSync } from "../../system/HewSync";
import { BaseAccount } from "../base/Account";

@HewSync.Type({
	scope: "user",
	name: "Account",
	table: "user",
	prefix: "ACCOUNT"
})
export class UserAccount extends BaseAccount {
	public static readonly type = "UserAccount";
	public readonly type = "UserAccount";

	@HewSync.Field({ createOnly: true, type: "ID" })
	public override readonly account: HewSync.ID<UserAccount>;

	@HewSync.Field({ type: "String" })
	public readonly email: string;

	private static cache = new Map<string, UserAccount>();

	public static get(account: HewSync.ID<UserAccount>) {
		this.initSubscriptions();

		let key = this.getKey(account);
		let item = this.cache.get(key)!;

		if (item === undefined) {
			item = new UserAccount({
				account: account.value,
				createdAt: "",
				updatedAt: "",
				name: "",
				lastEmailChangeAt: "",
				lastPasswordChangeAt: "",
				lastLoginAt: "",
				email: ""
			});
			this.cache.set(key, item);

			void (async () => {
				await HewSync.authPromise;
				item.fetch([new HewSync.QueryVariable("account", "ID", account.value)]);
			})();
		}

		return item;
	}

	protected reset() {
		this.createdAt = "";
		this.updatedAt = "";
		this.name = "";
		this.lastEmailChangeAt = "";
		this.lastPasswordChangeAt = "";
		this.lastLoginAt = "";
		this.email = "";
	}

	/**
	 * Raw get function, bypasses cache
	 *
	 * Do not use this function on the frontend, use get instead
	 */
	public static rawGet(account: HewSync.ID<UserAccount>) {
		return HewSync.queryGet<typeof UserAccount, UserAccount>(
			UserAccount,
			[new HewSync.QueryVariable("account", "ID", account.value)],
			false
		);
	}

	public static list(
		filter?: Partial<UserAccount> | ((data: UserAccount) => boolean)
	): HewSyncList<typeof UserAccount, UserAccount> {
		if (typeof filter === "object") {
			let list = new HewSyncList<typeof UserAccount, UserAccount>(this, (data) => {
				let result = true;
				for (let key in filter) {
					if (Array.isArray(filter[key])) {
						if (filter[key].length !== data[key].length || !filter[key].every((x, i) => x === data[key][i])) {
							result = false;
							break;
						}
					} else if (filter[key] instanceof HewSync.ID) {
						if (data[key].value !== filter[key].value) {
							result = false;
							break;
						}
					} else if (data[key] !== filter[key]) {
						result = false;
						break;
					}
				}
				return result;
			});

			return list;
		} else {
			let list = new HewSyncList<typeof UserAccount, UserAccount>(this, filter);

			return list;
		}
	}

	public static listBy(params?: Partial<Pick<UserAccount, "account">>): HewSyncList<typeof UserAccount, UserAccount> {
		let list = new HewSyncList<typeof UserAccount, UserAccount>(this, undefined, params);

		return list;
	}

	public static create(
		account: HewSync.ID<UserAccount>,
		data: {
			name?: string;
			lastEmailChangeAt?: HewSync.Timestamp;
			lastPasswordChangeAt?: HewSync.Timestamp;
			lastLoginAt?: HewSync.Timestamp;
			email?: string;
		}
	) {
		this.initSubscriptions();
		let inputs = [new HewSync.QueryVariable("account", "ID", account.value)];
		if (data.name !== undefined) {
			inputs.push(new HewSync.QueryVariable("name", "String", data.name));
		}
		if (data.lastEmailChangeAt !== undefined) {
			inputs.push(new HewSync.QueryVariable("lastEmailChangeAt", "String", data.lastEmailChangeAt));
		}
		if (data.lastPasswordChangeAt !== undefined) {
			inputs.push(new HewSync.QueryVariable("lastPasswordChangeAt", "String", data.lastPasswordChangeAt));
		}
		if (data.lastLoginAt !== undefined) {
			inputs.push(new HewSync.QueryVariable("lastLoginAt", "String", data.lastLoginAt));
		}
		if (data.email !== undefined) {
			inputs.push(new HewSync.QueryVariable("email", "String", data.email));
		}
		return HewSync.mutationCreate<typeof UserAccount, UserAccount>(UserAccount, inputs);
	}

	public static update(
		account: HewSync.ID<UserAccount>,
		data: {
			name?: string;
			lastEmailChangeAt?: HewSync.Timestamp;
			lastPasswordChangeAt?: HewSync.Timestamp;
			lastLoginAt?: HewSync.Timestamp;
			email?: string;
		},
		target?: UserAccount
	) {
		let inputs = [new HewSync.QueryVariable("account", "ID", account.value)];
		if (data.name !== undefined) {
			inputs.push(new HewSync.QueryVariable("name", "String", data.name));
		}
		if (data.lastEmailChangeAt !== undefined) {
			inputs.push(new HewSync.QueryVariable("lastEmailChangeAt", "String", data.lastEmailChangeAt));
		}
		if (data.lastPasswordChangeAt !== undefined) {
			inputs.push(new HewSync.QueryVariable("lastPasswordChangeAt", "String", data.lastPasswordChangeAt));
		}
		if (data.lastLoginAt !== undefined) {
			inputs.push(new HewSync.QueryVariable("lastLoginAt", "String", data.lastLoginAt));
		}
		if (data.email !== undefined) {
			inputs.push(new HewSync.QueryVariable("email", "String", data.email));
		}
		return HewSync.mutationUpdate<typeof UserAccount, UserAccount>(UserAccount, inputs, target);
	}

	public update(data: {
		name?: string;
		lastEmailChangeAt?: HewSync.Timestamp;
		lastPasswordChangeAt?: HewSync.Timestamp;
		lastLoginAt?: HewSync.Timestamp;
		email?: string;
	}) {
		return UserAccount.update(this.account, data, this);
	}

	public static remove(account: HewSync.ID<UserAccount>, target?: UserAccount) {
		return HewSync.mutationRemove<typeof UserAccount, UserAccount>(
			UserAccount,
			[new HewSync.QueryVariable("account", "ID", account.value)],
			target
		);
	}

	public remove() {
		return UserAccount.remove(this.account, this);
	}

	public static batchRemove(items: { account: string }[]) {
		let inputs: HewSync.QueryVariable[] = [new HewSync.QueryVariable("items", "[User_AccountKey]", items)];
		return HewSync.mutationBatchRemove<typeof UserAccount, UserAccount>(UserAccount, inputs);
	}

	/* eslint-disable */
	protected constructor(data: any) {
		super(Symbol.for("HewSyncType::Internal::Create") as unknown as void);

		(this as any).createdAt = new HewSync.Timestamp(data.createdAt);
		(this as any).updatedAt = new HewSync.Timestamp(data.updatedAt);
		(this as any).account = new HewSync.ID<any>(data.account);
		(this as any).name = data.name;
		(this as any).lastEmailChangeAt = data.lastEmailChangeAt;
		(this as any).lastPasswordChangeAt = data.lastPasswordChangeAt;
		(this as any).lastLoginAt = data.lastLoginAt;
		(this as any).email = data.email;
	}

	public get key() {
		return this.account.value;
	}

	protected apply(data: any) {
		(this as any).createdAt = new HewSync.Timestamp(data.createdAt);
		(this as any).updatedAt = new HewSync.Timestamp(data.updatedAt);
		(this as any).name = data.name;
		(this as any).lastEmailChangeAt = data.lastEmailChangeAt;
		(this as any).lastPasswordChangeAt = data.lastPasswordChangeAt;
		(this as any).lastLoginAt = data.lastLoginAt;
		(this as any).email = data.email;

		if (data.removed) {
			(this.removed as any) = true;
			this.onRemove.execute(this);
			UserAccount.cache.delete(this.key);
		} else {
			this.onUpdate.execute(this);
		}
	}
	/* eslint-enable */

	protected static from(data: any): UserAccount {
		return new UserAccount(data);
	}

	public static getKey(account: HewSync.ID<UserAccount>) {
		return account.value;
	}

	public static getKeyFromData(data: { account: string }) {
		return `${data.account}`;
	}

	private static onSubscriptionEvent = new BasicEvent<(data: any) => void>();

	private static subscriptionsInitialized = false;
	static initSubscriptions() {
		if (this.subscriptionsInitialized === false) {
			void HewSync.initSubscriptions(this, this.onSubscriptionEvent);
			this.subscriptionsInitialized = true;
		}
	}
}
