/* ****************
 * GENERATED CODE *
 **************** */
import { BasicEvent } from "@h4x/common";
import { HewSyncList } from "../../system/HewSyncList";
import { HewSync } from "../../system/HewSync";
import { BaseType } from "../../system/BaseType";
import { UserOrganization } from "./Organization";

@HewSync.Type({
	scope: "user",
	name: "Asset",
	table: "user",
	parent: UserOrganization,
	prefix: "ASSET"
})
export class UserAsset extends BaseType {
	public static readonly type = "UserAsset";
	public readonly type = "UserAsset";

	@HewSync.Field({ createOnly: true, generated: "util.autoId()", type: "ID" })
	public readonly asset: HewSync.ID<UserAsset>;

	@HewSync.Field({ reference: "Organization", type: "ID" })
	public readonly organization: HewSync.ID<UserOrganization>;

	@HewSync.Field({ prefix: "ASSET_KIND", type: "String", createOnly: true })
	public readonly kind: string;

	@HewSync.Field({ type: "String", required: true })
	public readonly fileType: string;

	@HewSync.Field({ type: "String", default: '"pending"' })
	public readonly status: string;

	@HewSync.Field({ type: "String", default: '""' })
	public readonly size: string;

	@HewSync.Field({ default: "{}", type: "JSON" })
	public readonly metadata: any;

	private static cache = new Map<string, UserAsset>();

	public static get(organization: HewSync.ID<UserOrganization>, kind: string, asset: HewSync.ID<UserAsset>) {
		this.initSubscriptions();

		let key = this.getKey(organization, kind, asset);
		let item = this.cache.get(key)!;

		if (item === undefined) {
			item = new UserAsset({
				organization: organization.value,
				kind: kind,
				asset: asset.value,
				createdAt: "",
				updatedAt: "",
				fileType: "",
				status: "pending",
				size: "",
				metadata: "{}"
			});
			this.cache.set(key, item);

			void (async () => {
				await HewSync.authPromise;
				item.fetch([
					new HewSync.QueryVariable("organization", "ID", organization.value),
					new HewSync.QueryVariable("kind", "String", kind),
					new HewSync.QueryVariable("asset", "ID", asset.value)
				]);
			})();
		}

		return item;
	}

	protected reset() {
		this.createdAt = "";
		this.updatedAt = "";
		this.fileType = "";
		this.status = "pending";
		this.size = "";
		this.metadata = "{}";
	}

	/**
	 * Raw get function, bypasses cache
	 *
	 * Do not use this function on the frontend, use get instead
	 */
	public static rawGet(organization: HewSync.ID<UserOrganization>, kind: string, asset: HewSync.ID<UserAsset>) {
		return HewSync.queryGet<typeof UserAsset, UserAsset>(
			UserAsset,
			[
				new HewSync.QueryVariable("organization", "ID", organization.value),
				new HewSync.QueryVariable("kind", "String", kind),
				new HewSync.QueryVariable("asset", "ID", asset.value)
			],
			false
		);
	}

	public static list(
		filter?: Partial<UserAsset> | ((data: UserAsset) => boolean)
	): HewSyncList<typeof UserAsset, UserAsset> {
		if (typeof filter === "object") {
			let list = new HewSyncList<typeof UserAsset, UserAsset>(this, (data) => {
				let result = true;
				for (let key in filter) {
					if (Array.isArray(filter[key])) {
						if (filter[key].length !== data[key].length || !filter[key].every((x, i) => x === data[key][i])) {
							result = false;
							break;
						}
					} else if (filter[key] instanceof HewSync.ID) {
						if (data[key].value !== filter[key].value) {
							result = false;
							break;
						}
					} else if (data[key] !== filter[key]) {
						result = false;
						break;
					}
				}
				return result;
			});

			return list;
		} else {
			let list = new HewSyncList<typeof UserAsset, UserAsset>(this, filter);

			return list;
		}
	}

	public static listBy(
		params?: Partial<Pick<UserAsset, "organization" | "asset">>
	): HewSyncList<typeof UserAsset, UserAsset> {
		let list = new HewSyncList<typeof UserAsset, UserAsset>(this, undefined, params);

		return list;
	}

	public static create(
		organization: HewSync.ID<UserOrganization>,
		kind: string,
		data: { fileType: string; status?: string; size?: string; metadata?: any }
	) {
		this.initSubscriptions();
		let inputs = [
			new HewSync.QueryVariable("organization", "ID", organization.value),
			new HewSync.QueryVariable("kind", "String", kind)
		];
		if (data.fileType !== undefined) {
			inputs.push(new HewSync.QueryVariable("fileType", "String", data.fileType));
		}
		if (data.status !== undefined) {
			inputs.push(new HewSync.QueryVariable("status", "String", data.status));
		}
		if (data.size !== undefined) {
			inputs.push(new HewSync.QueryVariable("size", "String", data.size));
		}
		if (data.metadata !== undefined) {
			inputs.push(new HewSync.QueryVariable("metadata", "AWSJSON", JSON.stringify(data.metadata)));
		}
		return HewSync.mutationCreate<typeof UserAsset, UserAsset>(UserAsset, inputs);
	}

	public static update(
		organization: HewSync.ID<UserOrganization>,
		kind: string,
		asset: HewSync.ID<UserAsset>,
		data: { fileType?: string; status?: string; size?: string; metadata?: any },
		target?: UserAsset
	) {
		let inputs = [
			new HewSync.QueryVariable("organization", "ID", organization.value),
			new HewSync.QueryVariable("kind", "String", kind),
			new HewSync.QueryVariable("asset", "ID", asset.value)
		];
		if (data.fileType !== undefined) {
			inputs.push(new HewSync.QueryVariable("fileType", "String", data.fileType));
		}
		if (data.status !== undefined) {
			inputs.push(new HewSync.QueryVariable("status", "String", data.status));
		}
		if (data.size !== undefined) {
			inputs.push(new HewSync.QueryVariable("size", "String", data.size));
		}
		if (data.metadata !== undefined) {
			inputs.push(new HewSync.QueryVariable("metadata", "AWSJSON", JSON.stringify(data.metadata)));
		}
		return HewSync.mutationUpdate<typeof UserAsset, UserAsset>(UserAsset, inputs, target);
	}

	public update(data: { fileType?: string; status?: string; size?: string; metadata?: any }) {
		return UserAsset.update(this.organization, this.kind, this.asset, data, this);
	}

	public static remove(
		organization: HewSync.ID<UserOrganization>,
		kind: string,
		asset: HewSync.ID<UserAsset>,
		target?: UserAsset
	) {
		return HewSync.mutationRemove<typeof UserAsset, UserAsset>(
			UserAsset,
			[
				new HewSync.QueryVariable("organization", "ID", organization.value),
				new HewSync.QueryVariable("kind", "String", kind),
				new HewSync.QueryVariable("asset", "ID", asset.value)
			],
			target
		);
	}

	public remove() {
		return UserAsset.remove(this.organization, this.kind, this.asset, this);
	}

	public static batchRemove(items: { organization: string; kind: string; asset: string }[]) {
		let inputs: HewSync.QueryVariable[] = [new HewSync.QueryVariable("items", "[User_AssetKey]", items)];
		return HewSync.mutationBatchRemove<typeof UserAsset, UserAsset>(UserAsset, inputs);
	}

	public static fileUpload(
		organization: HewSync.ID<UserOrganization>,
		kind: string,
		asset: HewSync.ID<UserAsset>,
		data: { contentType: string },
		target?: UserAsset
	) {
		let inputs = [
			new HewSync.QueryVariable("organization", "ID", organization.value),
			new HewSync.QueryVariable("kind", "String", kind),
			new HewSync.QueryVariable("asset", "ID", asset.value)
		];
		inputs.push(new HewSync.QueryVariable("contentType", "String", data.contentType));
		return HewSync.mutationFileUpload<typeof UserAsset, UserAsset>(UserAsset, inputs, target);
	}

	public fileUpload(data: { contentType: string }) {
		return UserAsset.fileUpload(this.organization, this.kind, this.asset, data, this);
	}

	/* eslint-disable */
	protected constructor(data: any) {
		super(Symbol.for("HewSyncType::Internal::Create") as unknown as void);

		(this as any).createdAt = new HewSync.Timestamp(data.createdAt);
		(this as any).updatedAt = new HewSync.Timestamp(data.updatedAt);
		(this as any).asset = new HewSync.ID<any>(data.asset);
		(this as any).organization = new HewSync.ID<any>(data.organization);
		(this as any).kind = data.kind;
		(this as any).fileType = data.fileType;
		(this as any).status = data.status;
		(this as any).size = data.size;
		(this as any).metadata = JSON.parse(data.metadata);
	}

	public get key() {
		return this.organization.value + "/" + this.kind + "/" + this.asset.value;
	}

	protected apply(data: any) {
		(this as any).createdAt = new HewSync.Timestamp(data.createdAt);
		(this as any).updatedAt = new HewSync.Timestamp(data.updatedAt);
		(this as any).kind = data.kind;
		(this as any).fileType = data.fileType;
		(this as any).status = data.status;
		(this as any).size = data.size;
		(this as any).metadata = JSON.parse(data.metadata);

		if (data.removed) {
			(this.removed as any) = true;
			this.onRemove.execute(this);
			UserAsset.cache.delete(this.key);
		} else {
			this.onUpdate.execute(this);
		}
	}
	/* eslint-enable */

	protected static from(data: any): UserAsset {
		return new UserAsset(data);
	}

	public static getKey(organization: HewSync.ID<UserOrganization>, kind: string, asset: HewSync.ID<UserAsset>) {
		return organization.value + "/" + kind + "/" + asset.value;
	}

	public static getKeyFromData(data: { organization: string; kind: string; asset: string }) {
		return `${data.organization + "/" + data.kind + "/" + data.asset}`;
	}

	private static onSubscriptionEvent = new BasicEvent<(data: any) => void>();

	private static subscriptionsInitialized = false;
	static initSubscriptions() {
		if (this.subscriptionsInitialized === false) {
			void HewSync.initSubscriptions(this, this.onSubscriptionEvent);
			this.subscriptionsInitialized = true;
		}
	}
}
